import React, { useEffect, useCallback, useState } from "react";
import { getAuth } from "firebase/auth";
import { Layout, Button, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { useCSVReader } from "react-papaparse";
import Menu from '../components/Menu';
import {
  GlobalOutlined,
  UploadOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { dataRef, setDb, onValueDb } from '../index';
import { authUsers } from '../components/auth/authUsers';


const { Content, Footer, Sider } = Layout;

function Dashboard() {
  const auth = getAuth();
  const navigate = useNavigate();
  const { CSVReader } = useCSVReader();
  const [hasPermisson, setHasPermission] = useState(false)
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    localStorage.removeItem("lastResultUopload");
  }, [])

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      // User is signed in
      const { email } = user;
      const findAuthUsers = authUsers.find(user => user.email === email)
      // Save the email to localStorage for future use
      if (findAuthUsers) {
        setHasPermission(true);
      }
    }
  }, []);

  return (
    <Layout style={{ minHeight: "100%" }}>
      <Sider trigger={null} collapsible collapsed={true} theme="dark" style={{ background: colorBgContainer }}>
        <div className="logo">
          <img src="/Archive.png" alt="image" style={{ width: 77 }} />
        </div>
        <Menu defaultSelectedKey="2" />
      </Sider>
      <Layout>
        <Content>
          {!hasPermisson ? (
            <div>
              User has no permission to importCSV
            </div>
          ) : (
            <CSVReader
              onUploadAccepted={(results) => {
                const newData = {
                  key1: JSON.stringify(results)
                }

                setDb(dataRef, newData)
                  .then(() => {
                    console.log('Data saved successfully!');
                  })
                  .catch((error) => {
                    console.error('Error saving data:', error);
                  });

                // const dataRef = ref(db, '/features');
                // push(dataRef, JSON.stringify(results))
                // navigate("/");
              }}
            >
              {({ getRootProps }) => (
                <div style={{ padding: 40 }}>
                  <Button {...getRootProps()} icon={<UploadOutlined />}>
                    Click to Upload CSV data
                  </Button>
                </div>
              )}
            </CSVReader>

          )}
        </Content>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
