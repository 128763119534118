
export const authUsers = [
    {
        email: "brandon@intelligencehouse.ca",
    },
    {
        email: "brandon@wumail.ca"
    },
    {
        email: "fvickert@gmail.com"
    },
    {
        email: 'cacuba@gmail.com'
    }
];