import { Button, Card, Checkbox, Form, Input, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
  sendEmailVerification,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
} from "firebase/auth";
import React, { useState, useRef } from "react";
import AuthCode from "react-auth-code-input";
import { Link, useNavigate } from "react-router-dom";

const { Title, Text } = Typography;

function AuthForm(props) {
  const auth = getAuth();
  const navigate = useNavigate();
  const [showMFA, setShowMFA] = useState(null);
  const [form] = useForm();
  const [verificationId, setVerificationId] = useState(undefined);
  const resolverRef = useRef(undefined);
  const [loadingMfaConfirm, setLoadingMfaConfirm] = useState(false);
  const [loadingCredConfirm, setLoadingCredConfirm] = useState(false);

  const onFinish = ({ username, password }) => {
    // Em producao tem q comentar isso se nao o captcha nao valida
    setLoadingCredConfirm(true);
    auth.settings.appVerificationDisabledForTesting = false;

    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-badge",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log(response);
        },
      },
      auth
    );

    signInWithEmailAndPassword(auth, username, password)
      .then(function (userCredential) {
        if (!userCredential.emailVerified) {
          sendEmailVerification(auth?.currentUser);
        }
        // User successfully signed in and is not enrolled with a second factor.
      })
      .catch(function (error) {
        if (error.code === "auth/multi-factor-auth-required") {
          // // The user is a multi-factor user. Second factor challenge is required.
          const resolver = getMultiFactorResolver(auth, error);
          resolverRef.current = resolver;
          const phoneAuthProvider = new PhoneAuthProvider(auth);
          const phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session,
          };

          return phoneAuthProvider
            .verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier)
            .then(function (verificationId) {
              // Ask user for the SMS verification code. Then:
              setVerificationId(verificationId);
              setShowMFA(true);
              setLoadingCredConfirm(false);
            });
          // ...
        } else if (error.code === "auth/wrong-password") {
          setLoadingCredConfirm(false);
          // Handle other errors such as wrong password.
          form.setFields([
            {
              name: "password",
              errors: ["Invalid password"],
            },
          ]);
        }
      });
  };

  const onFinishMfa = (values) => {
    const { mfaToken } = values;
    const cred = PhoneAuthProvider.credential(verificationId, mfaToken);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    // Complete sign-in.
    setLoadingMfaConfirm(true);
    resolverRef.current.resolveSignIn(multiFactorAssertion).then(() => {
      setLoadingMfaConfirm(false);
      navigate("/");
    }).catch(()=> {
      setLoadingMfaConfirm(false);
    });
  };

  const handleOnChange = (string) => {
    if (string.length === 6) {
      onFinishMfa({ mfaToken: string });
      return;
    }
    return;
  };

  return (
    <>
      <Card
        style={{
          position: "absolute",
          maxWidth: 400,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {showMFA ? (
          <>
            <Title level={2}>CODE VERIFICATION</Title>
            {loadingMfaConfirm ? (
              "Submitting mfa..."
            ) : (
              <Form onFinish={onFinishMfa}>
                <Form.Item
                  name={"mfaToken"}
                  rules={[
                    {
                      required: true,
                      message: "Please insert your code!",
                    },
                  ]}
                >
                  <AuthCode
                    allowedCharacters="numeric"
                    containerClassName="container"
                    inputClassName="input-mfa"
                    onChange={handleOnChange}
                  />
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  VERIFY
                </Button>{" "}
                <Button
                  type="primary"
                  className="login-form-button"
                  onClick={() => setShowMFA(null)}
                >
                  GO BACK
                </Button>
              </Form>
            )}
          </>
        ) : (
          <>
            <Title level={2}>Sign in</Title>
            {loadingCredConfirm ? (
              "Submitting credentials..."
            ) : (
              <>
                <Text type="secondary">
                  {" "}
                  Not your device? Use a private or incognito window to sing in.
                </Text>

                <Form
                  form={form}
                  style={{ marginTop: 30 }}
                  layout="vertical"
                  name="normal_login"
                  className="login-form"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                >
                  <Form.Item
                    label="Email"
                    name="username"
                    // initialValue={"fvickert@gmail.com"}
                    rules={[
                      {
                        required: true,
                        message: "Please input your Username!",
                      },
                    ]}
                  >
                    <Input
                      //   prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Username"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    // initialValue={"senha123"}
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>
                  {/* <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Link className="login-form-forgot" to="/forgot">
                  Forgot password
                </Link>
              </Form.Item> */}

                  <Form.Item>
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      Continue
                    </Button>{" "}
                    {/* Or <Link to="/register">register now!</Link> */}
                  </Form.Item>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <a href="">Sign in to a business account</a>
                    <a href="">Privacy policy</a>
                  </div>
                </Form>
              </>
            )}
          </>
        )}
      </Card>
      <div id="recaptcha-badge"></div>
    </>
  );
}

export default AuthForm;
