import { UploadOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import { useState, useEffect, useCallback, useRef } from "react";
import { Resizable } from "react-resizable";
import { useCSVReader } from "react-papaparse";
import Currency from "currency.js";
import { dataRef, setDb, onValueDb } from '../index';

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false,
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const _columns = [
  {
    title: "PROPERTY TYPE",
    dataIndex: "propertyType",
    width: "auto",
    width: 150,
    ellipsis: true,
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "ADDRESS",
    dataIndex: "address",
    width: 300,
    ellipsis: true,
  },
  {
    title: "BUILDING NAME",
    dataIndex: "buildingName",
    width: 300,
    ellipsis: true,
  },
  {
    title: "BED",
    dataIndex: "bed",
    width: 50,
    ellipsis: true,
    align: "right",
  },
  {
    title: "BATH",
    dataIndex: "bath",
    width: 60,
    ellipsis: true,
    align: "right",
  },
  {
    title: "RENT",
    dataIndex: "rent",
    sorter: (a, b) => {
      return Currency(a.rent).value - Currency(b.rent).value;
    },
    width: 120,
    ellipsis: true,
    render: (text) => {
      return Currency(text).format();
    },
    align: "right",
  },
  {
    title: "SIZE",
    dataIndex: "size",
    sorter: (a, b) => {
      const newASize = a.size ? Number.parseFloat(a.size) : 0;
      const newBSize = b.size ? Number.parseFloat(b.size) : 0;
      // console.log(newASize, a.size, newBSize, b.size);
      return newASize - newBSize;
    },
    width: 120,
    ellipsis: true,
    align: "right",
  },
  {
    title: "PSF",
    dataIndex: "psf",
    render: (text) => {
      return <span style={{ color: "orange" }}>{Currency(text).format()}</span>;
    },
    sorter: (a, b) => Currency(a.psf).value - Currency(b.psf).value,
    width: 120,
    ellipsis: true,
    align: "right",
  },
  {
    title: "YEAR BUILT",
    dataIndex: "yearBuilt",
    width: 100,
    align: "right",
  },
  {
    title: "SCRAPE DATE",
    dataIndex: "scrapeDate",
    width: 120,
    align: "right",
  },
  {
    title: "LISTING NAME",
    dataIndex: "listingName",
    width: 100,
    ellipsis: true,
  },
  {
    title: "SOURCE",
    dataIndex: "source",
    width: 120,
    ellipsis: true,
  },
  {
    title: "IMAGE",
    dataIndex: "image",
    render: (text) => (
      <a href={text} target="_blank" rel="noreferrer">
        Image
      </a>
    ),
    width: 100,
    ellipsis: true,
  },
  {
    title: "LISTING",
    dataIndex: "listing",
    render: (text) => (
      <a href={text} target="_blank" rel="noreferrer">
        Link
      </a>
    ),
    width: 80,
    ellipsis: true,
  },
];

// rowSelection object indicates the need for row selection

const PropertiesTable = ({
  setMapFeatures,
  bounds,
  setPropertyTypes,
  setBedNumbers,
  filterFormFields,
  filteredIds,
  featuresToExport,
  setFeaturesToExport,
  openPopUp,
}) => {
  const { CSVReader } = useCSVReader();
  const [data, setData] = useState([]);
  const originalList = useRef([]);
  const defaultFooter = () => {
    const total = data.length;
    const dataReduce = data.reduce(
      (acc, curr) => {
        return {
          avarageRent: acc.avarageRent + Currency(curr.rent).value,
          avarageSize: acc.avarageSize + Currency(curr.size).value,
          avaragePSF: acc.avaragePSF + Currency(curr.psf).value,
        };
      },
      { avarageRent: 0, avarageSize: 0, avaragePSF: 0 }
    );
    const { avarageRent, avarageSize, avaragePSF } = dataReduce;
    return (
      <div style={{ display: "flex" }}>
        <div style={{ marginLeft: 580 }}>
          <b>AVERAGE</b>
        </div>
        <div style={{ width: 137, textAlign: "right", marginLeft: 35 }}>
          <span>RENT</span> {Currency(avarageRent / total).format()}
        </div>
        <div style={{ width: 137, textAlign: "right" }}>
          <span>SIZE</span> {(avarageSize / total).toFixed()}
        </div>
        <div style={{ width: 137, textAlign: "right", color: "orange" }}>
          <span>PSF</span> {Currency(avaragePSF / total).format()}
        </div>
        <span style={{flex: 1}}></span>
        <div style={{ width: 137, textAlign: "right" }}>
          {Currency(data.length, { symbol: '', precision: 0 }).format()} <span>Listings</span>
        </div>
      </div>
    );
  };
  const [lastResult, setLastResult] = useState(undefined);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [columns, setColumns] = useState(_columns);
  //   const scroll = {
  //     y:240,
  //     x:'100vw'
  //   };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);

    const updatedData = data.map((item) => {
      const isSelected = newSelectedRowKeys.find(
        (selectedItem) => selectedItem === item.key
      );
      return { ...item, selected: isSelected ? 1 : 0 };
    });

    const updatedDataSameCord = updatedData.map((item) => {
      const hasSameCordSelected = updatedData.find(feature =>
        feature.selected && (feature.lat === item.lat && feature.lng === item.lng)
      )

      return { ...item, sameCordSelected: hasSameCordSelected ? 1 : 0 };
    });

    const features = updatedDataSameCord.map((item, index) => {
      const { lng, lat } = item;
      return {
        type: "Feature",
        properties: {
          ...item,
        },
        geometry: {
          type: "Point",
          coordinates: [lng, lat],
        },
      };
    });
    setMapFeatures({
      type: "FeatureCollection",
      features: features,
    });

    setFeaturesToExport(updatedData.filter((item) => item.selected));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const tableProps = {
    size: "small",
    footer: defaultFooter,
    rowSelection,
  };

  const isDateInRange = (scrapeDate, startDate, endDate) => {
    const [day, month, year] = scrapeDate.split('-');
    const formattedDateString = `${year}-${month}-${day}`;
    const targetDate = new Date(formattedDateString);

    const targetTime = targetDate.getTime();
    const startTime = startDate.getTime();
    const endTime = endDate.getTime();

    return targetTime >= startTime && targetTime <= endTime;
  };

  const filterByBoundsAndFilterForm = (filter, bounds, filteredIds) => {
    return originalList.current.filter((property) => {
      const { propertyType, bed, rent, size, lat, lng, key, yearBuilt, scrapeDate } = property;
      const findIdFiltered = filteredIds.find((item) => item === key);
      console.log(filter)
      return (
        (filteredIds.length ? findIdFiltered : true) &&
        (filter.propertyType
          ? (propertyType === filter.propertyType ||
          filter.propertyType === "all")
          : true) &&
        (filter.bed ? (bed === filter.bed) || filter.bed === "all" : true) &&
        (Number(filter.rent_min)
          ? Number(rent) >= Number(filter.rent_min)
          : true) &&
        (Number(filter.rent_max)
          ? Number(rent) <= Number(filter.rent_max)
          : true) &&
        (Number(filter.size_min)
          ? Number(size) >= Number(filter.size_min)
          : true) &&
        (Number(filter.size_max)
          ? Number(size) <= Number(filter.size_max)
          : true) &&
        lat >= bounds._sw.lat &&
        lat <= bounds._ne.lat &&
        lng >= bounds._sw.lng &&
        lng <= bounds._ne.lng &&
        (filter.rangePicker ? isDateInRange(scrapeDate, new Date(filter.rangePicker[0].$d), new Date(filter.rangePicker[1].$d)) : true) &&
        (Number(filter.year_built_min)
          ? Number(yearBuilt) >= Number(filter.year_built_min)
          : true) &&
        (Number(filter.year_built_max)
          ? Number(yearBuilt) <= Number(filter.year_built_max)
          : true)
      )
    });
  };

  useEffect(() => {
    const filterList = filterByBoundsAndFilterForm(
      filterFormFields,
      bounds,
      filteredIds
    );
    setData(previousList => {
      return filterList.map(feature => {
        const find = featuresToExport.find(item => item.key === feature.key)
        return {
          ...feature,
          selected: find ? 1 : 0
        }
      });
    });

    const filterListNoFilteredIds = filterByBoundsAndFilterForm(
      filterFormFields,
      bounds,
      []
    ).map(feature => {
      const find = featuresToExport.find(item => item.key === feature.key)
      return {
        ...feature,
        selected: find ? 1 : 0
      }
    });
    updateMapFeatureLayer(filterListNoFilteredIds);
  }, [bounds, filterFormFields, featuresToExport]);

  useEffect(() => {
    const filterList = filterByBoundsAndFilterForm(
      filterFormFields,
      bounds,
      filteredIds
    );
    setData(filterList);
  }, [filteredIds]);

  const updateMapFeatureLayer = useCallback((filterList) => {
    const features = filterList.map((item) => {
      const { lng, lat } = item;
      return {
        type: "Feature",
        properties: {
          ...item,
        },
        geometry: {
          type: "Point",
          coordinates: [lng, lat],
        },
      };
    });
    setMapFeatures({
      type: "FeatureCollection",
      features: features,
    });
  }, []);

  useEffect(() => {
    onValueDb(dataRef, (snapshot) => {
      const data = snapshot.val();
      // Use the retrieved data
      if (data && data.key1) {
        const results = JSON.parse(data.key1);
        setLastResult(results);
      }
    }, (error) => {
      console.error('Error fetching data:', error);
    });



  }, []);

  useEffect(() => {
    if (!lastResult) return;

    const transformResult = lastResult?.data
      .slice(1, lastResult.data.length)
      .map((item, index) => {
        return {
          key: `${item[16]}-${index}`,
          propertyType: item[2],
          buildingName: item[3],
          address: item[1],
          bed: item[4],
          bath: item[5],
          rent: item[6],
          size: item[7],
          psf: item[8],
          yearBuilt: item[9],
          listingName: item[15],
          source: item[0],
          image: item[10],
          listing: item[17],
          lat: Number.parseFloat(item[11]),
          lng: Number.parseFloat(item[12]),
          scrapeDate: item[13],
          selected: 0,
          sameCordSelected: 0,
          city: item[14],
        };
      });

    const bedNumbers = new Set();

    const propertyTypes = Object.keys(
      transformResult.reduce((acc, curr) => {
        bedNumbers.add(curr.bed)
        return {
          ...acc,
          [curr.propertyType]: curr.propertyType,
        };
      }, {})
    ).filter((item) => item.slice(",").length > -1 && item !== "undefined");

    setPropertyTypes(propertyTypes);
  
    setBedNumbers([...bedNumbers].filter(item => item != undefined).sort());

    originalList.current = transformResult;
    // console.log(transformResult);

    const features = originalList.current.map((item, index) => {
      const { lng, lat } = item;
      return {
        type: "Feature",
        properties: {
          ...item,
        },
        geometry: {
          type: "Point",
          coordinates: [lng, lat],
        },
      };
    });
    setMapFeatures({
      type: "FeatureCollection",
      features: features,
    });
    setData(transformResult);
  }, [lastResult]);

  useEffect(() => {
    setSelectedRowKeys((current) => {
      return featuresToExport.map((item) => item.key);
    });
  }, [featuresToExport]);

  const handleResize =
    (index) =>
      (_, { size }) => {
        const newColumns = [...columns];
        newColumns[index] = {
          ...newColumns[index],
          width: size.width,
        };
        setColumns(newColumns);
      };

  const mergeColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  const onClickRow = record => {
    openPopUp(record)
  }

  return (
    <div>
      {data && data.length ? (
        <div id="table-property-resizable-container">
          <Table
            {...tableProps}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => { onClickRow(record) }, // click row
                onDoubleClick: (event) => { }, // double click row
                onContextMenu: (event) => { }, // right button click row
                onMouseEnter: (event) => { }, // mouse enter row
                onMouseLeave: (event) => { }, // mouse leave row
              };
            }}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            rowClassName="table-properties"
            scroll={{
              y: 280,
            }}
            components={{
              header: {
                cell: ResizableTitle,
              },
            }}
            size="small"
            pagination={{
              defaultPageSize: 8,
              responsive: true,
              showSizeChanger: false,
              showQuickJumper: true,
              showTotal: (total) => {
                return `Total ${total}`;
              },
            }}
            columns={mergeColumns}
            dataSource={data}
          />
        </div>
      ) : null}
    </div>
  );
};
export default PropertiesTable;
