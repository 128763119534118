import { ConfigProvider, theme } from "antd";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, push, set, onValue} from "firebase/database";
import React from "react";
import ReactDOM from "react-dom/client";
import AuthForm from "./components/auth/AuthForm";
import ImportCSV from './pages/ImportCSV';

import "antd/dist/reset.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import "./App.css";

const firebaseConfig = {
  apiKey: "AIzaSyDUJVuCwmmlY_EGhucwIGzh1q97bAWelkY",
  authDomain: "archive-app-7db9f.firebaseapp.com",
  databaseURL: "https://archive-app-7db9f-default-rtdb.firebaseio.com",
  projectId: "archive-app-7db9f",
  storageBucket: "archive-app-7db9f.appspot.com",
  messagingSenderId: "362240046865",
  appId: "1:362240046865:web:74283aad1e9da85887feb0"
};

initializeApp(firebaseConfig);

export const database = getDatabase();
export const dataRef = ref(database, 'path/to/data/features');
export const pushDb = push;
export const setDb = set;
export const onValueDb = onValue;

const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/importCSV",
    element: <ImportCSV />,
  },
  {
    path: "/login",
    element: <AuthForm />,
  },
]);

root.render(
  <ConfigProvider
    theme={{
      algorithm: theme.darkAlgorithm,
      token: {
        // colorPrimaryBg: "red",
        // colorBgBase: "green",
        // colorBgContainer: "pink"
        
      },
    }}
  >
    <RouterProvider router={router} />
  </ConfigProvider>
);
