import React, { useEffect, useCallback, useState } from "react";
import { Menu as AntMenu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { GlobalOutlined, UploadOutlined, LogoutOutlined } from '@ant-design/icons';
import { getAuth, signOut } from "firebase/auth";
import { authUsers } from '../components/auth/authUsers';

const { Item } = AntMenu;

const Menu = ({ defaultSelectedKey }) => {
    const navigate = useNavigate();
    const auth = getAuth();
    const [hasPermisson, setHasPermission] = useState(false)

    const logout = useCallback(() => {
        signOut(auth)
            .then(() => {
                // Sign-out successful.
            })
            .catch((error) => {
                // An error happened.
            });
    }, []);

    const handleItemClick = (key) => {
        if (key === '1') {
            navigate('/');
            return;
        } else if (key === '2') {
            navigate("/importCSV");
            return;
        } else if (key === '3') {
            logout();
            return;
        }
    };

    useEffect(() => {
        const user = auth.currentUser;
        if (user) {
            // User is signed in
            const { email } = user;
            const findAuthUsers = authUsers.find(user => user.email === email)
            // Save the email to localStorage for future use
            if (findAuthUsers) {
                setHasPermission(true);
            }
        }
    }, []);

    return (
        <AntMenu mode="inline" defaultSelectedKeys={[defaultSelectedKey]}>
            <Item key="1" icon={<GlobalOutlined />} onClick={() => handleItemClick('1')}>
                Maps
            </Item>
            {hasPermisson && (<Item key="2" icon={<UploadOutlined />} onClick={() => handleItemClick('2')}>
                Upload CSV
            </Item>)}
            <Item key="3" icon={<LogoutOutlined />} onClick={() => handleItemClick('3')}>
                Logout
            </Item>
        </AntMenu>
    );
};

export default Menu;
