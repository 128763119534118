import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Cascader,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  TreeSelect,
  Typography,
} from "antd";
import { useState, useEffect, useRef } from "react";

const { Title } = Typography;
const { RangePicker } = DatePicker;


// rowSelection object indicates the need for row selection

const download = function (data) {
  // Creating a Blob for having a csv file format
  // and passing the data with type
  const blob = new Blob([data], { type: "text/csv" });

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob);

  // Creating an anchor(a) tag of HTML
  const a = document.createElement("a");

  // Passing the blob downloading url
  a.setAttribute("href", url);

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute("download", "download.csv");

  // Performing a download with click
  a.click();
};

const csvmaker = function (data, headers) {
  // Empty array for storing the values
  const csvRows = [];

  csvRows.push(headers.join(","));

  // Pushing Object values into array
  // with comma separation
  data.map((item) => {
    const {
      source,
      address,
      propertyType,
      listingName,
      buildingName,
      yearBuilt,
      bed,
      bath,
      rent,
      size,
      psf,
      image,
      lat,
      lng,
      listing,
      scrapeDate,
      city,
    } = item;

    const line = `${source},"${address}", ${propertyType}, "${buildingName}", ${bed},${bath},"${rent}","${size}","${psf}", ${yearBuilt},${image},${lat},${lng},"${scrapeDate}","${city}", "${listingName}",${listing}`;

    csvRows.push(line);
  });

  // Returning the array joining with new line
  return csvRows.join("\n");
};

const FilterSider = ({
  featuresToExport = [],
  setFeaturesToExport,
  bounds,
  propertyTypes,
  bedNumbers,
  setFilterFormFields,
  filterFormFields,
}) => {
  const exportCSV = () => {
    if (!featuresToExport.length) return;

    const headers = [
      "Source",
      "Address",
      "Property Type",
      "Building Name",
      "Bed",
      "Bath",
      "Rent",
      "Size",
      "$PSF",
      "Year Built",
      "Image",
      "Lat",
      "Lon",
      "Scrape Date",
      "City",
      "Listing Name",
      "Listing",
    ];

    const data = featuresToExport.map((item) => {
      const {
        source,
        address,
        propertyType,
        listingName,
        buildingName,
        yearBuilt,
        bed,
        bath,
        rent,
        size,
        psf,
        image,
        lat,
        lng,
        scrapeDate,
        city,
        listing,
        key,
      } = item;
      return {
        source,
        address,
        propertyType,
        listingName,
        buildingName,
        yearBuilt,
        bed,
        bath,
        rent,
        size,
        psf,
        image,
        lat,
        lng,
        scrapeDate,
        city,
        listing,
        key,
      };
    });

    const csvdata = csvmaker(data, headers);
    download(csvdata);
  };

  const formRef = useRef(null);

  const onValuesChange = (changedValues, allFields) => {
    setFilterFormFields({
      ...filterFormFields,
      ...allFields,
    });
  };

  const onChangePropertyType = (value) => {
    setFilterFormFields({
      ...filterFormFields,
      propertyType: value,
    });
  };

  const onChangeRangePicker = (value) => {
    setFilterFormFields({
      ...filterFormFields,
      rangePicker: value,
    });
  }

  const onChangeBed = (value) => {
    setFilterFormFields({
      ...filterFormFields,
      bed: value,
    });
  };

  const cleanFilters = () => {
    setFilterFormFields({});
    const formInstance = formRef.current;
    formInstance.resetFields();
  };

  const cleanExports = () => {
    setFeaturesToExport([]);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Title level={4}>FILTERS</Title>
      <Form
        ref={formRef}
        onValuesChange={onValuesChange}
        style={{
          maxWidth: 600,
        }}
        layout="vertical"
      >
        <Form.Item>
          <RangePicker onChange={onChangeRangePicker} />
        </Form.Item>
        <Form.Item label="PROPERTY TYPE">
          <Select onChange={onChangePropertyType} defaultValue="all">
            <Select.Option value={"all"}>All</Select.Option>
            {propertyTypes.map((item, index) => {
              return (
                <Select.Option key={index} value={item}>
                  {item}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="BED">
          <Select onChange={onChangeBed}>
            <Select.Option value={"all"}>All</Select.Option>
            {bedNumbers.map((item, index) => {
              return (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="RENT" style={{ marginBottom: 0 }}>
          <Form.Item
            name="rent_min"
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          >
            <Input placeholder="Min." />
          </Form.Item>
          <Form.Item
            name="rent_max"
            rules={[{ required: true }]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
          >
            <Input placeholder="Max." />
          </Form.Item>
        </Form.Item>
        <Form.Item label="SIZE (SF)" style={{ marginBottom: 0 }}>
          <Form.Item
            name="size_min"
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          >
            <Input placeholder="Min." />
          </Form.Item>
          <Form.Item
            name="size_max"
            rules={[{ required: true }]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
          >
            <Input placeholder="Max." />
          </Form.Item>
        </Form.Item>
        <Form.Item label="YEAR BUILT" style={{ marginBottom: 0 }}>
          <Form.Item
            name="year_built_min"
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          >
            <Input placeholder="Min." />
          </Form.Item>
          <Form.Item
            name="year_built_max"
            rules={[{ required: true }]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
          >
            <Input placeholder="Max." />
          </Form.Item>
        </Form.Item>
      </Form>
      <Button size="small" onClick={cleanFilters}>
        Clean filters
      </Button>
      <div style={{ flex: 1 }}></div>
      <Button
        type="primary"
        size="large"
        block
        style={{ height: 80, marginBottom: 20 }}
        disabled={!featuresToExport.length}
        onClick={exportCSV}
      >
        {featuresToExport.length} Export
      </Button>
      <Button size="small" onClick={cleanExports}>
        Clean exports
      </Button>
    </div>
  );
};
export default FilterSider;
