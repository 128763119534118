import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";

function App() {
  const navigate = useNavigate();
  const auth = getAuth();
  
  const [loggedUser, setLoggedUser] = useState(undefined)
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      // ...
      setLoggedUser(user);
    } else {
      setLoggedUser(undefined);
      navigate("/login");
    }
  });

  return !loggedUser ? 'loading' : <Dashboard />;
}

export default App;
