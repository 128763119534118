import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";

import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import ReactDOM from "react-dom/client";
import { Carousel, Icon, Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

import "./Map.css";
import * as turf from "@turf/turf";

mapboxgl.workerClass = MapboxWorker;

mapboxgl.accessToken =
  "pk.eyJ1IjoiaWNrZXJ0IiwiYSI6ImNsZmw3NHc1ajAyYjYzc2swZTU5czZsMTgifQ.O-G6erorFy_-t7SNr_FurA";

const Map = forwardRef(
  (
    {
      mapFeatures,
      setBounds,
      setFeaturesToExport,
      setFilteredIds,
      featuresToExport,
    },
    ref
  ) => {
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const [lng, setLng] = useState(-113.323975);
    const [lat, setLat] = useState(53.631611);
    const [zoom, setZoom] = useState(7);
    const [loadingMap, setLoadingMap] = useState(true);
    const popUpRef = useRef(
      new mapboxgl.Popup({ offset: 15, closeButton: false })
    );
    const featureExportRef = useRef(null);
    const Popup = ({
      features,
      setFeaturesToExport,
      featuresToExport = [],
    }) => {
      const carouselRef = useRef(null);
      const [currentSlide, setCurrentSlide] = useState(0);

      const handleNext = () => {
        setCurrentSlide((prevSlide) => prevSlide + 1);
      };

      const handlePrev = () => {
        setCurrentSlide((prevSlide) => prevSlide - 1);
      };

      const handleAfterChange = (current) => {
        setCurrentSlide(current);
      };

      if (!features.length) return;

      const totalSlides = features.length;

      return (
        <>
          <Carousel
            style={{ width: 300, color: "#fff" }}
            afterChange={handleAfterChange}
            ref={carouselRef}
            dots={false}
          // arrows
          // prevArrow={<LeftOutlined />}
          // nextArrow={<RightOutlined />}
          >
            {features.map((feature) => {
              const {
                address,
                listingName,
                bed,
                size,
                propertyType,
                rent,
                psf,
                bath,
                key,
              } = feature.properties;

              const checked = !!featureExportRef?.current.find(
                (item) => item.key === key
              );

              return (
                <div key={key}>
                  <div style={{ height: 200, marginTop: 10, color: "#fff" }}>
                    <div className="popup-container">
                      <div style={{ display: 'flex' }}>
                        <p>{address}</p>
                        <span style={{ flex: 1 }} />
                        <p>{propertyType}</p>
                      </div>
                      <div>
                        <p className="popup-listingName" alt={listingName} title={listingName}>{listingName}</p>
                        <p>
                          {bed} Bed/{bath} Bath
                        </p>
                        <p>{size} SF</p>
                      </div>
                      <span className="flex"></span>
                    </div>
                    <div className="popup-container" style={{ display: 'flex' }}>
                      <div>
                        <input
                          defaultChecked={checked}
                          type="checkbox"
                          id={`feature-${key}`}
                          name="feature"
                          onChange={(e) => {
                            if (e?.target?.checked) {
                              setFeaturesToExport((current) => [
                                ...current,
                                feature.properties,
                              ]);
                            } else {
                              setFeaturesToExport((current) =>
                                current.filter((item) => item.key !== key)
                              );
                            }
                          }}
                        ></input>
                        <label htmlFor="feature">Select</label>
                      </div>
                      <span className="flex"></span>
                      <div>
                        <p>
                          ${rent}{' '}{' '} ({psf} PSF)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <span
              className="carousel-nav"
              onClick={() => carouselRef.current.prev()}
              style={{ cursor: 'pointer' }}
            >
              {`<Previous`}
            </span>
            <span style={{ margin: 10 }} className="carousel-pagination">
              [<span className="current-slide">{currentSlide + 1}</span>
              <span className="total-slides">/{totalSlides}</span>]
            </span>
            <span
              className="carousel-nav"
              onClick={() => carouselRef.current.next()}
              style={{ cursor: 'pointer' }}
            >
              {`Next>`}
            </span>
          </div>

        </>
      );
    };

    const addLayersCustom = () => {
      if (!mapRef?.current?.getSource("properties")) {
        mapRef.current.addSource("properties", {
          type: "geojson",
          // Use a URL for the value for the `data` property.
          data: {
            type: "FeatureCollection",
            features: [],
          },
          // cluster: true,
        });
      }
      if (!mapRef.current.getLayer("properties-layer")) {
        mapRef.current.addLayer({
          id: "properties-layer",
          type: "circle",
          source: "properties",
          // "source-layer": "original",
          paint: {
            "circle-radius": 5,
            "circle-stroke-width": 1,
            "circle-color": [
              "match",
              ["get", "selected"],
              1,
              "green",
              0,
              "red",
              /* other */ "blue",
            ],
            "circle-stroke-color": "white",
          },
        });
      }

      if (!mapRef.current.getLayer("properties-highlighted")) {
        mapRef.current.addLayer({
          id: "properties-highlighted",
          type: "circle",
          source: "properties",
          // "source-layer": "original",
          paint: {
            "circle-radius": 5,
            "circle-stroke-width": 1,
            "circle-color": [
              "match",
              ["get", "selected"],
              1,
              "green",
              0,
              "#6e599f",
              /* other */ "blue",
            ],
            "circle-stroke-color": "white",
          },
          filter: ["in", "keys", ""],
        });
      }

      setLoadingMap(false);
    };

    // Initialize map when component mounts
    useEffect(() => {
      if (!mapRef.current) return;
      if (loadingMap) return;

      const sourceProperties = mapRef?.current?.getSource("properties");

      if (sourceProperties) {
        sourceProperties.setData(mapFeatures);
      }
    }, [mapFeatures, loadingMap]);

    const createPopUp = useCallback(
      (event) => {
        // console.log(" featuresToExport", featuresToExport);
        const popupNode = document.createElement("div");
        const root = ReactDOM.createRoot(popupNode);
        root.render(
          <Popup
            features={event.features}
            setFeaturesToExport={setFeaturesToExport}
            featuresToExport={featureExportRef.current}
          />
        );
        popUpRef.current
          .setLngLat(event.lngLat)
          .setDOMContent(popupNode)
          .addTo(mapRef.current);
      },
      [featuresToExport]
    );

    useEffect(() => {
      featureExportRef?.current?.map((item) => {
        const inputs = document.querySelectorAll(`#feature-${item.key}`);
        if (inputs.length) {
          inputs.forEach((item) => {
            item.checked = false;
          });
        }
      });
      featureExportRef.current = featuresToExport;
      featureExportRef.current.map((item) => {
        const inputs = document.querySelectorAll(`#feature-${item.key}`);
        if (inputs.length) {
          inputs.forEach((item) => {
            item.checked = true;
          });
        }
      });
    }, [featuresToExport]);

    useEffect(() => {
      const currentSyle = null;
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [lng, lat],
        zoom: zoom,
      });

      const geocoder = new MapboxGeocoder({
        // Initialize the geocoder
        accessToken: mapboxgl.accessToken, // Set the access token
        mapboxgl: mapRef.current, // Set the mapbox-gl instance
        marker: false, // Do not use the default marker style
        placeholder: "Search for places", // Placeholder text for the search bar
        bbox: [-140.99778, 41.6751050889, -52.6480987209, 83.23324], // Boundary for Berkeley
        // proximity: {
        //   longitude: -122.25948,
        //   latitude: 37.87221
        // } // Coordinates of UC Berkeley
      });

      mapRef.current.addControl(geocoder);

      // Add navigation control (the +/- zoom buttons)
      mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-right");

      mapRef.current.on("move", async () => {
        const currentZoom = mapRef.current.getZoom().toFixed(2);
        setLng(mapRef.current.getCenter().lng.toFixed(4));
        setLat(mapRef.current.getCenter().lat.toFixed(4));

        const data = draw.getAll();
        // const answer = document.getElementById("calculated-area");
        if (data.features.length > 0) {
        } else {
          setBounds(mapRef.current.getBounds());
        }

        if (
          currentZoom >= 13 &&
          mapRef.current.style.stylesheet?.id !== "satellite-streets-v12"
        ) {
          // await setLoadingMap(true);
          mapRef.current.setStyle("mapbox://styles/mapbox/satellite-streets-v12");
          // await setLoadingMap(false);
        } else if (
          currentZoom < 13 &&
          mapRef.current.style.stylesheet?.id === "satellite-streets-v12"
        ) {
          // await setLoadingMap(true);
          mapRef.current.setStyle("mapbox://styles/mapbox/streets-v11");
          // await setLoadingMap(false);
        }
        setZoom(currentZoom);
      });
      mapRef.current.on("styledata", (e) => {
        if (!mapRef.current.getLayer()) addLayersCustom();
      });

      mapRef.current.on("mouseenter", "properties-layer", (event) => {
        // const feature = event.features[0];
        // const { name, description } = feature.properties;
        if (popUpRef.current && popUpRef.current.clicked) return;
        createPopUp(event);
        // document.querySelector(`#feature-${key}`).onchange = (e) => {
        //   if (e?.target?.checked) {
        //     addNewExport(feature);
        //   }
        // };

        // mapRef.current.getCanvas().style.cursor = "pointer";
      });

      let clickCoords = {};
      mapRef.current.on("click", "properties-layer", (e) => {
        clickCoords = e.point;
        e.originalEvent.stopPropagation();

        if (popUpRef.current && popUpRef.current.clicked) {
          popUpRef.current.remove();
          popUpRef.current.addTo(mapRef.current);
          popUpRef.current.setLngLat(e.lngLat).addTo(mapRef.current);

          popUpRef.current.clicked = true;
        } else {
          createPopUp(e);
          popUpRef.current.clicked = true;
        }
        // popUpRef.current.options.closeButton = true;
        // popUpRef.current.options.closeOnClick = true;
        return;
      });

      mapRef.current.on("click", (e) => {
        if (clickCoords.x !== e.point.x && clickCoords.y !== e.point.y) {
          if (popUpRef.current) {
            popUpRef.current.remove();
            popUpRef.current.clicked = false;
          }
        }
        clickCoords = {};
      });

      mapRef.current.on("mouseleave", "properties-layer", () => {
        if (!popUpRef.current.clicked) {
          popUpRef.current.remove();
        }

        mapRef.current.getCanvas().style.cursor = "";
      });

      const draw = new MapboxDraw({
        displayControlsDefault: false,
        // Select which mapbox-gl-draw control buttons to add to the map.
        controls: {
          polygon: true,
          trash: true,
        },
      });
      mapRef.current.addControl(draw, "top-left");

      const updateArea = (e) => {
        const data = draw.getAll();
        if (!data || !data.features || !data.features[0]) {
          return;
        }
        // console.log(data);

        const filterPolygon = data.features[0];
        // console.log("filterPolygon", filterPolygon.geometry.coordinates);

        const bbox = turf.bbox(filterPolygon);
        // console.log("bbox", bbox);

        const featuresInBBox = mapRef.current.queryRenderedFeatures(
          [
            mapRef.current.project([bbox[0], bbox[1]]),
            mapRef.current.project([bbox[2], bbox[3]]),
          ],
          { layers: ["properties-layer"] }
        );
        // console.log("featuresInBBox", featuresInBBox);

        const filteredFeatures = featuresInBBox.filter((f) =>
          isWithinOrIntersecting(filterPolygon, f)
        );
        // console.log("filteredFeatures", filteredFeatures);

        const keys = filteredFeatures.map((feature) => feature.properties.key);
        mapRef.current.setFilter("properties-highlighted", [
          "in",
          "key",
          ...keys,
        ]);
        setFilteredIds(keys);
      };

      const isWithinOrIntersecting = (filterPolygon, feature) => {
        if (feature.geometry.type == "MultiPolygon") {
          // TODO: Handle multipolygon...
        } else {
          return (
            turf.booleanIntersects(filterPolygon, feature) ||
            turf.booleanContains(filterPolygon, feature)
          );
        }
      };

      mapRef.current.on("draw.delete", () => {
        setTimeout(() => {
          draw.deleteAll();
          mapRef.current.setFilter("properties-highlighted", [
            "in",
            "key",
            ...[],
          ]);
          setFilteredIds([]);
        }, 0);
      });

      mapRef.current.on("draw.create", updateArea);
      mapRef.current.on("draw.update", updateArea);

      setTimeout(() => {
        mapRef.current.resize();
      }, 1);

      // window.addEventListener("resize", () => {
      //   map.resize();
      // });

      // Clean up on unmount

      mapRef.current.on("load", () => {
        addLayersCustom();
      });

      return () => mapRef.current.remove();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(ref, () => ({
      openPopUp(record) {
        const { lng, lat } = record;
        createPopUp({
          lngLat: [record.lng, record.lat],
          features: [{
            properties: {
              ...record
            }
          }],
        });
        mapRef.current.flyTo({
          center: [record.lng, record.lat]
        });
      },
    }));

    return (
      <div
        id="container"
        style={{
          height: "100%",
          position: "relative",
        }}
      >
        <div className="sidebarStyle">
          <div>
            Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
          </div>
        </div>
        <div className="map-container" ref={mapContainerRef} />
        {loadingMap ? "Loading map..." : ""}
      </div>
    );
  }
);

export default Map;
