import React, { useState, useCallback, useRef } from "react";
import { getAuth, signOut } from "firebase/auth";
import { Layout, Button, theme } from "antd";
import PropertiesTable from "../components/PropertiesTable";
import FilterSider from "../components/FilterSider";
import Map from "../components/mapbox/Map";
import { useNavigate } from "react-router-dom";
import {
  GlobalOutlined,
  UploadOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import Menu from '../components/Menu';

const { Content, Footer, Sider } = Layout;

function Dashboard() {
  const auth = getAuth();
  const refMap = useRef();
  const [mapFeatures, setMapFeatures] = useState([]);
  const [featuresToExport, setFeaturesToExport] = useState([]);
  const [bounds, setBounds] = useState(null);
  const [filteredIds, setFilteredIds] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [bedNumbers, setBedNumbers] = useState([]);
  const [filterFormFields, setFilterFormFields] = useState({});
  const navigate = useNavigate();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const logout = useCallback(() => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  }, []);

  const cleanImportedTable = useCallback(() => {
    // localStorage.removeItem("lastResultUopload");
    setTimeout(() => {
      setFilterFormFields({});
      setFeaturesToExport([]);
      setFilteredIds([]);
      setMapFeatures({
        type: "FeatureCollection",
        features: [],
      });
    }, 0);
    window.dispatchEvent(new Event("storage"));
  }, []);

  const openPopUp = record => {
    refMap.current.openPopUp(record);
  }

  return (
    <Layout style={{ minHeight: "100%" }}>
      <Sider trigger={null} collapsible collapsed={true} theme="dark" style={{background: colorBgContainer  }}>
        <div className="logo">
            <img src="/Archive.png" alt="image" style={{width: 77}}/>

        </div>
        <Menu defaultSelectedKey="1" />
      </Sider>
      <Layout>
        <Content>
          <Map
            ref={refMap}
            mapFeatures={mapFeatures}
            setBounds={setBounds}
            setFeaturesToExport={setFeaturesToExport}
            featuresToExport={featuresToExport}
            setFilteredIds={setFilteredIds}
          />
        </Content>
        <Footer
          style={{
            padding: 4,
            maxHeight: 400,
            overflow: "hidden",
          }}
        >
          <PropertiesTable
            setMapFeatures={setMapFeatures}
            bounds={bounds}
            setPropertyTypes={setPropertyTypes}
            setBedNumbers={setBedNumbers}
            filterFormFields={filterFormFields}
            filteredIds={filteredIds}
            featuresToExport={featuresToExport}
            setFeaturesToExport={setFeaturesToExport}
            openPopUp={openPopUp}
          />
        </Footer>
      </Layout>
      <Sider width={250} theme="dark" style={{background: colorBgContainer  }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            padding: 10,
          }}
        >
          <div>
            {/* <Button size="small" onClick={logout} style={{ marginBottom: 10 }}>
              Logout
            </Button>
            <Button
              size="small"
              onClick={cleanImportedTable}
              style={{ marginBottom: 10, marginLeft: 10 }}
            >
              Clean imported table
            </Button> */}
          </div>
          <div style={{ flex: 1, display: "flex" }}>
            <FilterSider
              featuresToExport={featuresToExport}
              setFeaturesToExport={setFeaturesToExport}
              propertyTypes={propertyTypes}
              bedNumbers={bedNumbers}
              setFilterFormFields={setFilterFormFields}
              filterFormFields={filterFormFields}
            />
          </div>
        </div>
        {/* <pre
          style={{
            maxWidth: `100%`,
            overflow: "hidden",
          }}
        >
          {JSON.stringify(auth?.currentUser, " ", 2)}
        </pre> */}
      </Sider>
    </Layout>
  );
}

export default Dashboard;
